<template>
  <div>
    <div class="public-margin-bottom flex-right">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addMemberClass"
        >新增会员分类</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      style="width: 100%"
      border
    >
      <el-table-column prop="KindNo" label="分类编号"> </el-table-column>
      <el-table-column prop="KindName" label="分类名称"> </el-table-column>
      <el-table-column label="是属总部">
        <template slot-scope="scope">
          <div>{{ scope.row.HeadMark | whether }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="onEditMemberClass(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="onDeleteMemberClass(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <EditDialog
      :formData="formData"
      :etitle="etitle"
      :dialogTableVisible="showDialog"
      :callFatherFunction="callFatherFunction"
      @closeDialog="onCloseDialog"
    >
    </EditDialog>
  </div>
</template>

<script>
import member from "@/api/member.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  components: { EditDialog },

  data() {
    return {
      tableData: [],
      formData: {},
      tableMaxHeight: "",
      etitle: "",
      showDialog: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 48);
    },

    async initPageData() {
      try {
        let { data } = await member.getMemberkindlist();
        this.tableData = data;
      } catch (e) {
        console.error(e);
      }
    },

    addMemberClass() {
      this.etitle = "添加会员分类";
      this.formData = {
        KindName: "",
      };
      this.showDialog = true;
    },

    onEditMemberClass(event) {
      this.etitle = "修改会员分类";
      this.formData = event;
      this.showDialog = true;
    },

    async onDeleteMemberClass(event) {
      this.$confirm(`是否删除该‘${event.KindName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await member.deleteMemberkind({
              kind_guid: event.KindGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    onCloseDialog() {
      this.initPageData();
    },

    callFatherFunction() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
