<template>
  <div>
    <el-dialog
      :title="etitle"
      :visible.sync="dialogTableVisible"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        label-width="80px"
      >
        <el-form-item label="分类名称" prop="KindName">
          <el-input
            v-model="formData.KindName"
            placeholder="请输入单位名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button type="primary" @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import member from "@/api/member.js";
export default {
  props: {
    etitle: {
      type: String,
      default: "添加",
    },

    dialogTableVisible: {
      type: Boolean,
      default: false,
    },

    callFatherFunction: {
      type: Function,
    },

    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      formRules: {
        KindName: [
          { required: true, message: "请输入折扣名称", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 8 个字符", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //
    onCloseDialog(formName) {
      this.callFatherFunction();
      this.$refs[formName].resetFields();
      this.$emit("closeDialog");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let submitData = {
              KindName: this.formData.KindName,
            };
            // 如果有 KindGuid 就是修改
            if (this.formData.KindGuid) {
              submitData.KindGuid = this.formData.KindGuid;
              let { data, errcode, errmsg } = await member.updateMemberkind(
                submitData
              );
              if (errcode == 0) {
                this.onCloseDialog("formRules");
                this.$message({
                  message: "修改成功!",
                  type: "success",
                });
              } else {
                this.$message.error(errmsg);
              }
            } else {
              let { data, errcode, errmsg } = await member.addMemberkind(
                submitData
              );
              if (errcode == 0) {
                this.onCloseDialog("formRules");
                this.$message({
                  message: "添加成功!",
                  type: "success",
                });
              } else {
                this.$message.error(errmsg);
              }
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>